<template>
	<div>
		<div class="dF jSB">
			<h5>Set Up Signing Order</h5>
			<div class="dF" v-if="$mq !== 'lg'">

				<a-switch v-model="showSigningRoute">
					<a-icon slot="checkedChildren" type="check" />
					<a-icon slot="unCheckedChildren" type="close" />
				</a-switch>
				<span class="ml-2">
					Preview Signing Route
				</span>
			</div>
		</div>
		<a-divider />
		<div class="dF">
			<div class="f1" style="max-width:750px;" v-if="!showSigningRoute || $mq === 'lg'">

				<!-- <a-checkbox v-model="requiresRepReview" class="ml-4 mb-4">
					Requires Rep to Review Contract
				</a-checkbox>

				<template v-if="requiresRepReview">
					<SigningParty v-model="reviewer" :repReviewer="requiresRepReview" />
					<a-divider />
				</template> -->


				<template v-if="step5.purchaserSide && step5.purchaserSide.length">
					<div v-for="(signer, signerI) in step5.purchaserSide" :key="signer.id">
						<SigningParty v-model="step5.purchaserSide[signerI]"
							:IDVerificationWorkflows="IDVerificationWorkflows" class="mb-3" />
					</div>
				</template>
				<p v-else class="text-danger">
					You have no one from {{ reservation.type === "purchase" ? "Purchaser" : "Tenant" }} side assigned.
				</p>

				<a-divider />

				<template v-if="step5.vendorSide && step5.vendorSide.length">
					<template v-for="(signer, signerI) in step5.vendorSide">
						<SigningParty :choices="appSettings.participants" v-model="step5.vendorSide[signerI]"
							:key="signer + signerI" />
					</template>
				</template>

				<template v-if="step5.others && step5.others.length">

					<a-divider />

					<div v-for="(signer, signerI) in step5.others" :key="signer.id + signerI">
						<SigningParty :choices="appSettings.participants" v-model="step5.others[signerI]" :additional="true"
							@delete="step5.others.splice(signerI, 1)" />
					</div>
				</template>

				<a-divider />

				<a-button icon="plus" type="primary" size="large" @click="addRecipient">Additional Recipients</a-button>
			</div>
			<div class="f1 dF fC aC" v-if="$mq === 'lg' || showSigningRoute">
				<div style="position:sticky; top:10px">
					<h4 class="text-center mt-2">Sign Route Preview</h4>
					<SignRoutePreview :data="signRoutePreview" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { generateContractPreview, isProductAvailable, getContractTemplateName } from './tools'
import { ruleReq, arrToObj } from 'bh-mod'
import SigningParty from '@/components/forms/SigningParty'
import SignRoutePreview from '@/components/forms/SignRoutePreview'
import moment from 'moment'

export default {
	components: { SigningParty, SignRoutePreview },
	props: ['reservation'],
	data: () => ({
		finalReservation: {},
		envelopeFileKey: null,
		envelopeData: null,
		latestOrder: 1,
		showSigningRoute: false,
		requiresRepReview: false,
		step5: {
			purchaserSide: [],
			vendorSide: [],
			others: [],
		},
		IDVerificationWorkflows: []
	}),

	methods: {
		ruleReq,
		async createEnvelope() {

			this.$store.commit('LOAD', true)
			let reservation = {
				...this.finalReservation
			}

			if (reservation.type === 'lease') {
				reservation.price = reservation.offer.totalRent
			} else if (reservation.offer.purchasePrice) {
				reservation.price = reservation.offer.purchasePrice
			}

			reservation.envelope = {
				name: getContractTemplateName(reservation, this.instance.productType) || this.currentContract.name,
				file: {
					template: this.currentContract.id,
					key: this.envelopeFileKey
				},
				signers: this.signRoutePreview,
				requiresRepReview: this.requiresRepReview
			}

			let url = '/reservations/:instance'
			if (this.$route.params.id && reservation.id) {
				reservation.resend = true
				url = `/reservations/:instance/${reservation.id}`

				if (reservation.envelopes) {
					let mainEnvelope = reservation.envelopes.find(x => x.main)
					if (mainEnvelope) {
						reservation.envelope.id = mainEnvelope.id
						delete reservation.envelopes
					}
				}
			}

			if(this.instance.productType === 'lowrise') {
				reservation.lot = reservation.product.lot.id
				reservation.unit = reservation.product.unit.id
			} else {
				reservation.floor = reservation.product.floor.id
				reservation.unit = reservation.product.unit.id
			}

			reservation.amount = reservation.offer.reservationAmount;

			if (reservation.status === 'submitted') {
				url = `/reservations/:instance/${reservation.id}/process-reservation`
			}

			this.$api[this.$route.params.id && reservation.id && reservation.status !== 'submitted' ? 'put' : 'post'](url, reservation)
				.then((resp) => {
					if (resp && resp.data && resp.data.id) {
						let reservation = resp.data
						this.$router.push(`/reservation/${reservation.id}`)
						this.$message.success('Document Sent for Signing')
					}
				})
				.catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err, `An error occurred while creating your reservation. Please try again.`))
					}
				})

				.finally(() => {
					this.$store.commit('LOAD', false)
					this.$store.commit('CLOSE_PREVIEW')

				})

		},
		async generatePreview() {
			if (this.$store.state.savingAsDraft) {
				this.$emit('done', { ...this.finalReservation })
				return
			}
			const reservation = this.finalReservation
			this.$store.commit('LOAD', true)
			if (!isProductAvailable(reservation, this)) {
				if (this.instance.productType == 'lowrise') {
					this.$message.error('Sorry this lot is no longer available. Please select another one.')
				} else {
					this.$message.error('Sorry this unit is no longer available. Please select another one.')
				}
				return
			}

			try {
				const { key, url } = await generateContractPreview(reservation, this)
				this.envelopeFileKey = key
				this.$store.commit('REVIEW_SEND', { preview: url, callback: this.createEnvelope })
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'An error occurred while generating your contract. Please try again.'))
				}
			}

			this.$store.commit('LOAD', false)


		},
		async validate() {
			this.reservation.signingParty = [...this.step5.vendorSide, ...this.step5.others];
			let reservation = { ...this.reservation }

			if (this.$store.state.appData.allSettings && this.$store.state.appData.allSettings.app && this.$store.state.appData.allSettings.app.options) {
				reservation.other.defaults = this.$store.state.appData.allSettings.app.options
			}

			reservation.offer.date = Date.now()
			reservation.offer.solicitorReviewDate = moment().add(7, 'days');

			if (reservation.offer && reservation.offer.inclusions && reservation.offer.inclusions.length && !this.inclusionsTransformed) {
				let incs = arrToObj(this.appSettings.inclusions)
				reservation.offer.inclusions = reservation.offer.inclusions.map(incId => {
					if (incs[incId]) {
						return incs[incId].body || ''
					}
					return ''
				})
				this.inclusionsTransformed = true
			}

			this.finalReservation = reservation
			this.generatePreview()
		},
		addRecipient() {
			this.step5.others.push({
				canBeRemoved: true,
				action: 'cc',
				canSign: false,
				vendor: true,
				id: Date.now(),
				name: '',
				order: this.latestOrder += 1,
				role: '',
				supportingText: '',
				email: '',
				note: '',
				choice: 'other',
				idVerificationWorkflowId: "N/A"
			})
		},

		async fetchIDVerificationWorkflows() {
			try {
				const { data } = await this.$api.get(`transactions/:instance/identity-verification-workflows`)
				this.IDVerificationWorkflows = data;
			} catch (err) {
				// Error handling
			}
		}
	},
	computed: {
		signRoutePreview() {
			let route = {}

			if (this.requiresRepReview) {
				route = {
					0: {
						order: 0,
						signers: [{
							...this.reviewer
						}]
					}
				}
			}

			this.step5.purchaserSide.forEach(p => {
				if (!p.name.trim() || !p.email.trim()) return
				if (!route[p.order]) route[p.order] = { order: p.order, signers: [] }
				route[p.order].signers.push({ ...p, idVerificationWorkflowId: p.idVerificationWorkflowId === 'N/A' ? null : p.idVerificationWorkflowId })
			})
			this.step5.vendorSide.forEach(p => {
				if (!p.name.trim() || !p.email.trim()) return
				if (!route[p.order]) route[p.order] = { order: p.order, signers: [] }
				route[p.order].signers.push({ ...p, idVerificationWorkflowId: p.idVerificationWorkflowId === 'N/A' ? null : p.idVerificationWorkflowId })
			})
			this.step5.others.forEach(p => {
				if (!p.name.trim() || !p.email.trim()) return
				if (!route[p.order]) route[p.order] = { order: p.order, signers: [] }
				route[p.order].signers.push({ ...p, idVerificationWorkflowId: p.idVerificationWorkflowId === 'N/A' ? null : p.idVerificationWorkflowId })
			})

			let routes = Object.values(route).sort((a, b) => a.order > b.order ? 1 : -1)

			return routes
		},
		appSettings() {
			return this.$store.state.appData.allSettings.app && this.$store.state.appData.allSettings.app.options
		},
		currentContract() {
			if (!this.reservation.contract) return {}
			return this.$store.state.appData.templates[this.reservation.template] || {}
		},
		instance() {
			return this.$store.state.instance
		},
		user() {
			return this.$store.state.user.user
		},
		reviewer: {
			get() {
				const user = {
					id: this.user.id,
					order: 0,
					action: 'approve',
					name: this.user.firstName + ' ' + this.user.lastName,
					role: 'Reviewer',
					email: this.user.email
				}

				return user
			},
			set(val) {

			}
		}
	},
	created() {
		this.fetchIDVerificationWorkflows();

		let contract = this.currentContract
		let defaultParticipants = this.appSettings.participants || []
		let reservation = this.reservation

		let signingObj = {
			solicitor: false,
			agent: false,
			signingParty: false,
			purchasers: false,
			purchaserSide: true,
			action: 'cc',
			actionDisabled: false,
			disabled: false,
			other: true,
			predefined: true,
			id: Date.now(),
			name: '',
			role: '',
			supportingText: '',
			email: '',
			note: '',
			choice: 'other',
			idVerificationWorkflowId: "N/A"
		}

		let { pages = [] } = contract

		let maxPurchasers = 0
		let maxSigningParties = 0

		let allPurchasers = []
		let signingParties = []
		let allSigningParties = []

		let signingReps = {
			solicitor: false,
			agent: false,
		}

		let signingList = {
			purchasers: {},
			reps: {},
			signingParty: {},
		}

		const transactionType = "Purchaser";
		pages.forEach((page, pageI) => {
			let { fields = [] } = page

			fields.forEach(spot => {
				let { field, text } = spot
				let fV = field.value
				let fT = field.type
				let canSign = false
				if (fT === 'initial' || fT === 'sign' || fT === 'signedDate') canSign = true

				if (fV.includes('purchasers[]')) {
					if (text.purchasers > maxPurchasers) maxPurchasers = text.purchasers
					if (!allPurchasers.includes(text.purchasers)) allPurchasers.push(text.purchasers)

					if (fT === 'initial' || fT === 'sign' || fT === 'signedDate') {
						if (!signingParties.includes(text.purchasers)) signingParties.push(text.purchasers)
						if (reservation.purchasers[text.purchasers - 1] && reservation.purchasers[text.purchasers - 1].email) {
							if (!signingList.purchasers[text.purchasers]) {
								signingList.purchasers[text.purchasers] = {
									...signingObj,
									canSign,
									actionDisabled: true,
									disabled: true,
									order: 1,
									id: `${transactionType}${text.purchasers},`,
									action: canSign ? 'sign' : 'cc',
									name: '',
									role: `${transactionType} #${text.purchasers}`,
									supportingText: `${transactionType} #${text.purchasers}`,
									email: '',
									purchasers: text.purchasers,
									signLocations: {}
								}
							}

							signingList.purchasers[text.purchasers].signLocations[spot.id] = {
								...spot,
								name: reservation.purchasers[text.purchasers - 1].fullName,
								email: reservation.purchasers[text.purchasers - 1].email,
								phone: reservation.purchasers[text.purchasers - 1].phone,
								page: pageI,
								file: 0,
							}
						}
					}
				}


				if (fV.includes('signingParty[]')) {
					if (text.signingParty > maxSigningParties) maxSigningParties = text.signingParty
					if (!allSigningParties.includes(text.signingParty)) allSigningParties.push(text.signingParty)

					if (fT === 'initial' || fT === 'sign' || fT === 'signedDate') {
						if (!signingParties.includes(text.signingParty)) signingParties.push(text.signingParty)

						if (!signingList.signingParty[text.signingParty]) {
							signingList.signingParty[text.signingParty] = {
								...signingObj,
								canSign,
								actionDisabled: true,
								disabled: false,
								order: 1,
								signingParty: text.signingParty,
								id: 'signingParty' + text.signingParty,
								action: canSign ? 'sign' : 'cc',
								name: '',
								role: text.signingParty === 1 ? `Main Signing Party` : `Additional Signing Party #${text.signingParty - 1}`,
								supportingText: text.signingParty === 1 ? `Main Signing Party` : `Additional Signing Party #${text.signingParty - 1}`,
								email: '',
								signLocations: {}
							}
						}

						signingList.signingParty[text.signingParty].signLocations[spot.id] = {
							...spot,
							page: pageI,
							file: 0,
						}
					}
				}


				if (fT === 'initial' || fT === 'sign' || fT === 'signedDate') {
					if (field.value.includes('reps.agent')) {
						signingReps.agent = true
						if (reservation.reps && reservation.reps.agent && reservation.reps.agent.fullName && reservation.reps.agent.email) {
							if (!signingList.reps.agent) {
								signingList.reps.agent = {
									...signingObj,
									canSign,
									actionDisabled: false,
									disabled: true,
									order: 1,
									id: 'agent',
									action: canSign ? 'sign' : 'cc',
									name: '',
									role: `${transactionType}'s Agent`,
									supportingText: `${transactionType}'s Agent`,
									email: '',
									signLocations: {}
								}
							}
							signingList.reps.agent.signLocations[spot.id] = {
								...spot,
								name: reservation.reps.agent.fullName,
								email: reservation.reps.agent.email,
								phone: reservation.reps.agent.phone,
								page: pageI,
								file: 0,
							}
						}
					}

					if (field.value.includes('reps.solicitor')) {
						signingReps.solicitor = true
						if (reservation.reps && reservation.reps.solicitor && reservation.reps.solicitor.fullName && reservation.reps.solicitor.email) {
							if (!signingList.reps.solicitor) {
								signingList.reps.solicitor = {
									...signingObj,
									canSign,
									actionDisabled: false,
									disabled: true,
									order: 1,
									id: 'solicitor',
									action: canSign ? 'sign' : 'cc',
									name: '',
									role: `${transactionType}'s Lawyer`,
									supportingText: `${transactionType}'s Lawyer`,
									email: '',
									signLocations: {},
								}
							}

							signingList.reps.solicitor.signLocations[spot.id] = {
								...spot,
								name: reservation.reps.solicitor.fullName,
								email: reservation.reps.solicitor.email,
								phone: reservation.reps.solicitor.phone,
								page: pageI,
								file: 0,
							}
						}
					}
				}
			})
		})

		let purchaserSide = []

		purchaserSide = reservation.purchasers.map((purchaser, purchaserI) => {
			if (signingList.purchasers[purchaserI + 1]) {
				signingList.purchasers[purchaserI + 1] = {
					...signingList.purchasers[purchaserI + 1],
					name: purchaser.fullName,
					id: `${transactionType}${purchaserI + 1}_sign`,
					supportingText: `${transactionType} #${purchaserI + 1}`,
					role: `${transactionType} #${purchaserI + 1}`,
					order: this.latestOrder,
					email: purchaser.email,
					phone: purchaser.phone,
					country: purchaser.country,
				}
				return signingList.purchasers[purchaserI + 1]
			}

			return {
				...signingObj,
				canSign: false,
				actionDisabled: false,
				disabled: true,
				order: this.latestOrder,
				id: `${transactionType}${purchaserI + 1}_notsign`,
				action: 'cc',
				name: purchaser.fullName,
				role: `${transactionType} #${purchaserI + 1}`,
				supportingText: `${transactionType} #${purchaserI + 1}`,
				email: purchaser.email,
				phone: purchaser.phone,
				country: purchaser.country,
				purchasers: purchaserI + 1,
			}
		})

		if (reservation.reps && reservation.reps.agent && reservation.reps.agent.email) {
			let person = reservation.reps.agent
			let canSign = Boolean(signingList.reps.agent)

			let agent = {
				...signingList.reps.agent,
				actionDisabled: false,
				disabled: true,
				canBeIgnored: true,
				order: this.latestOrder += 1,
				id: 'agent',
				canSign,
				action: canSign ? 'sign' : 'cc',
				name: person.firstName + ' ' + person.lastName,
				role: `${transactionType}'s Agent`,
				supportingText: `${transactionType}'s Agent`,
				email: person.email,
				phone: person.phone,
				idVerificationWorkflowId: "N/A"
			}

			purchaserSide.push(agent)
		}

		if (reservation.reps && reservation.reps.solicitor && reservation.reps.solicitor.email) {
			let person = reservation.reps.solicitor
			let canSign = Boolean(signingList.reps.solicitor)

			let solicitor = {
				...signingList.reps.solicitor,
				other: false,
				canBeIgnored: true,
				id: 'solicitor',
				actionDisabled: false,
				disabled: true,
				order: this.latestOrder += 1,
				canSign,
				action: canSign ? 'sign' : 'cc',
				name: person.firstName + ' ' + person.lastName,
				role: `${transactionType}'s Lawyer`,
				supportingText: `${transactionType}'s Lawyer`,
				email: person.email,
				phone: person.phone,
				idVerificationWorkflowId: "N/A"
			}

			purchaserSide.push(solicitor)
		}

		allSigningParties.forEach((sP, sPi) => {
			let obj = signingObj
			let canSign = false
			if (signingList.signingParty[sP]) {
				obj = signingList.signingParty[sP]
				canSign = true
			}

			let party = {
				...obj,
				roleDisabled: true,
				canSign,
				disabled: false,
				vendor: true,
				order: this.latestOrder += 1,
				id: Date.now() + sPi,
				action: canSign ? 'sign' : 'cc',
				name: '',
				email: '',
				supportingText: sP === 1 ? 'Main Signing Party' : `Additional Signing Party #${sP - 1}`,
				role: sP === 1 ? 'Main Signing Party' : `Additional Signing Party #${sP - 1}`,
				choice: 'other',
				actionDisabled: false,
			}

			if (defaultParticipants && defaultParticipants[sPi]) {
				party = {
					...party,
					choice: defaultParticipants[sPi].email,
					name: defaultParticipants[sPi].name,
					// role:defaultParticipants[sPi].role,
					role: sP === 1 ? `Main Signing Party` : `Additional Signing Party #${sP - 1}`,
					email: defaultParticipants[sPi].email,
					company: defaultParticipants[sPi].company,
					id: defaultParticipants[sPi].id || party.id
				}
			}

			this.step5.vendorSide.push(party)
		})
		this.step5.purchaserSide = purchaserSide

		if (this.$route.params.id) {
			if (this.reservation.envelopes && this.reservation.envelopes[0]) {
				this.requiresRepReview = !!this.reservation.envelopes[0].requiresRepReview
			}
			if (this.reservation.signingParty && this.reservation.signingParty.length) {
				const mainSigningParty = this.reservation.signingParty.filter(sp => {
					if (!sp.canBeRemoved) {
						const found = this.appSettings.participants.find(p => p.id == sp.id);
						return found ? true : false;
					}
					return false;
				})
				const others = this.reservation.signingParty.filter(sp => {
					if (sp.canBeRemoved) {
						const found = this.appSettings.participants.find(p => p.id == sp.id);
						return found ? true : false;
					}
					return false;
				})

				if (mainSigningParty && this.step5.vendorSide && (mainSigningParty.length <= this.step5.vendorSide.length)) {
					this.step5.vendorSide = mainSigningParty;
				}

				if (others && others.length) {
					this.step5.others = others;
				}
			}
		}
	},
	mounted() {
		window.moment = moment
		this.$emit('setFn', this.validate)
	}
}
</script>

<style lang="scss">
.signer-action-icon {
	position: absolute;
	top: 10px;
	right: 10px;
	background: white;
	pointer-events: none;
	padding: 5px;
	border-radius: 50%;
	box-shadow: 0 2px 10px rgba(100, 100, 100, 0.2);
	font-size: 10px;
}
</style>
